import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import './styles/oche_dhis2_datasets.css';
import '../index.css';
import { apiCall } from '../providers/api';
import { ExpansionTile } from './oche_canevas';
import { getNewItemWithId } from '../utils/utils';
import { copyWith } from '../logic/slice';
import { AgeConditions, ExactAnswerConditions, ExactPathConditions, ExactPathSum, OptionsConditions, SexConditions, SumAnswerConditions } from './oche_dhis2_aggregations';

export default function OcheDHIS2Datasets() {
    const [currentDataSet, setDataSet] = useState(null);
    return (
        <div className='oche-dhis2-datasets'>
            <ViewDataSets onTap={(dSet) => {
                setDataSet({ ...dSet })
            }} />
            <ViewOverDataSet key={currentDataSet} dataSet={currentDataSet} />
        </div>
    )
}

function ViewDataSets({ onTap = () => { } }) {
    const { dataSets } = useSelector(state => state.default);
    const [query, setQuery] = useState('')
    let itemsToDisplay = dataSets.filter((el) => String(el.name).toLowerCase().includes(String(query).toLowerCase()))
    itemsToDisplay.sort((a, b) => a.name.localeCompare(b.name))
    return <div className='o-d-d o-d-d-v-d'>
        <p>Supported Datasets ({itemsToDisplay.length})</p>
        <div className='divider' />
        <input
            placeholder='Filter by name'
            onChange={(ev) => {
                const { value } = ev.target;
                setQuery(value)
            }}
        />
        <div className='s-dataset-list'>
            {itemsToDisplay.map((dSet) => {
                const { id, name, version, periodType, created } = dSet;
                return <div key={id} className="s-datasetb" onClick={(ev) => {
                    ev.stopPropagation();
                    onTap(dSet)
                }}>
                    <div className='s-d-body'>
                        <p>{String(name).trim()}</p>
                        <div className='divider' />
                        <p>v{version} - {periodType} -  {new Date(created).toLocaleString()}</p>
                    </div>
                </div>
            })}
        </div>
    </div>
}

async function fecthDataSet({ id = null, url = "", usrn = "", pwd = "" }) {
    const path = `dataSets/${id}?fields=id,name,version,periodType,created,dataSetElements[dataElement[id,name,categoryCombo[id,name,categoryOptionCombos[id,name]]`;
    return await apiCall({
        baseUrl: url,
        endPoint: path,
        method: 'GET',
        mode: 'no-cors',
        headers: {
            Authorization: `Basic ${window.btoa(`${usrn}:${pwd}`)}`,
        },
    }).then((res) => {
        if (res.error) {
            return {};
        } else {
            return res;
        }
    })

}

function ViewOverDataSet({ dataSet = null }) {
    const { name, version } = dataSet ?? {};
    const { dhis2 } = useSelector(state => state.default);
    const [item, setItem] = useState({})
    const [loading, setLoading] = useState(false)
    const { dataSets } = useSelector(state => state.default);
    const dispatch = useDispatch();

    useEffect(() => {
        setItem({})
    }, [dataSet])

    let dataElements = item?.dataSetElements ?? [];
    dataElements.sort((a, b) => a.dataElement.name.localeCompare(b.dataElement.name))
    let currentDels = dataSets.find((el) => el.id === dataSet?.id)?.dataElements ?? [];
    return dataSet ? <div className='o-d-d'>
        <div className='oche-inputs-title'>
            <p className='p-medium'>{name} v{version}</p>
            <i className={`fa fa-refresh ${loading ? 'fa-spin' : ''}`} onClick={(ev) => {
                const { baseUrl, username, password } = dhis2 ?? {};
                setLoading(true)
                fecthDataSet({
                    id: dataSet.id,
                    url: baseUrl,
                    usrn: username,
                    pwd: password
                }).then((_) => {
                    setLoading(false)
                    setItem(_)
                })
            }} />
        </div>
        <div className='divider' />
        <div className='s-dataset-list'>
            {dataElements.map((dEl) => {
                const { id, name } = dEl.dataElement;
                let isConfigured = currentDels.find((el) => el.id === id)
                return <ExpansionTile key={id} title={name} data={<CategoryComboView
                    dataElement={isConfigured ?? dEl.dataElement}
                    onChange={(updatedDataElement) => {
                        dispatch(copyWith({
                            dataSets: [...dataSets].map((dS) => {
                                return dS.id === dataSet.id ? {
                                    ...dataSet,
                                    dataElements: [...currentDels.filter((el) => el.id !== updatedDataElement.id), { ...updatedDataElement }]
                                } : dS;
                            })
                        }))
                    }}
                />} />
            })}
        </div>
    </div> : <p className='o-d-d center-o-o-d'>Select a DHIS2 dataset to continue</p>
}

function CategoryComboView({ dataElement = {}, onChange = () => { } }) {
    const { registers, languages } = useSelector(state => state.default);
    const { name, categoryOptionCombos } = dataElement.categoryCombo;
    let oCombos = categoryOptionCombos ?? [];
    let arreayToSort = [...oCombos];
    arreayToSort.sort((a, b) => a.name.localeCompare(b.name))
    return <div className='categoryCombo'>
        <div className='categoryCombo-left'>
            <p id='combName'>{name}</p>
            <div className='divider' />
            <div className='input-labeled'>
                <label>Register</label>
                <select name='register' value={dataElement?.register} onChange={(ev) => {
                    ev.stopPropagation();
                    onChange({ ...dataElement, 'register': ev.target.value })
                }}>
                    <option value={''}>Choose</option>
                    {[...registers].map((e) => {
                        return <option value={e.code}>{e.code}</option>
                    })}
                </select>
            </div>
            <div className='input-labeled'>
                <label>Display name</label>
                {[...languages].map((lng, i) => {
                    const iName = `displayName_${lng.code}`;
                    return <input
                        key={i}
                        name={iName}
                        placeholder={`Display name (${lng.name})`}
                        type={'name'}
                        value={dataElement[iName]}
                        onChange={(ev) => {
                            ev.stopPropagation();
                            onChange({ ...dataElement, [iName]: ev.target.value });
                        }}
                    />
                })}
            </div>
        </div>
        <div className='s-dataset-list'>
            <ExpansionTile title='Aggregation conditions' data={<AggregationConditions
                conditions={dataElement?.aggregationConditions ?? []}
                dataElement={dataElement}
                onChange={(conditions) => {
                    onChange({ ...dataElement, aggregationConditions: conditions })
                }}
            />} />
            <div className='divider'/>
            {arreayToSort?.map((catOpComb) => {
                const { id, name } = catOpComb;
                return <ExpansionTile key={id} title={name} data={<CategoryComboConfig
                    categoryOptionCombo={catOpComb}
                    dataElement={dataElement}
                    onChange={(updatedCategoryCombo) => {
                        onChange({
                            ...dataElement, 'categoryCombo': {
                                ...dataElement.categoryCombo, categoryOptionCombos: arreayToSort.map((e) => {
                                    return e.id === updatedCategoryCombo.id ? { ...e, ...updatedCategoryCombo } : e;
                                })
                            },
                        })
                    }}
                />} />
            })}
        </div>
    </div>
}


function CategoryComboConfig({ categoryOptionCombo = {}, dataElement = {}, onChange = () => { } }) {
    const { languages } = useSelector(state => state.default);
    return <div className='categoryComboConfig'>
        <div className='oche-languages-form'>
            <div className='input-labeled'>
                <label>DataElement</label>
                <input className='input-disable' type='text' placeholder='DataElement' value={dataElement.id} />
            </div>
            <div className='input-labeled'>
                <label>CategoryOptionCombo</label>
                <input className='input-disable' type='text' placeholder='CategoryOptionCombo' value={categoryOptionCombo.id} />
            </div>
        </div>
        <div className='input-labeled'>
            <label>Display name</label>
            <div className='oche-languages-form'>
                {[...languages].map((lng, i) => {
                    const iName = `displayName_${lng.code}`;
                    return <input
                        key={i}
                        name={iName}
                        placeholder={`Display name (${lng.name})`}
                        type={'name'}
                        value={categoryOptionCombo[iName]}
                        onChange={(ev) => {
                            ev.stopPropagation();
                            onChange({ ...categoryOptionCombo, [iName]: ev.target.value });
                        }}
                    />
                })}
            </div>
        </div>

        <ExpansionTile title='Aggregation conditions' data={<AggregationConditions
            conditions={categoryOptionCombo?.aggregationConditions ?? []}
            dataElement={dataElement}
            onChange={(conditions) => {
                onChange({ ...categoryOptionCombo, aggregationConditions: conditions })
            }}
        />} />
    </div>
}

function AggregationConditions({ conditions = [], onChange = () => { }, dataElement = {} }) {
    return <div className='aggregationconditions'>
        <div className='oche-title'>
            <label>Conditions ({conditions.length})</label>
            <button onClick={(ev) => {
                ev.preventDefault();
                onChange([...conditions, getNewItemWithId({ currentList: conditions })]);
            }}>Add</button>
        </div>
        <div className='divider' />
        {conditions.map((condition, i) => {
            return <ExpansionTile key={condition.id} title={condition?.type ?? ''} data={<div className='options-inputs-row'>
                <p>{i + 1}.</p>
                <AggregationConfig
                    condition={condition}
                    dataElement={dataElement}
                    onChange={(updatedCondtion) => {
                        onChange([...conditions.map((element) => {
                            return element.id !== condition.id ? element : { ...element, ...updatedCondtion }
                        })])
                    }} />
                <i className='modal-header fa fa-xmark' onClick={(ev) => {
                    ev.stopPropagation();
                    onChange([...conditions.filter((element) => element.id !== condition.id)]);
                }}></i>
            </div>} />
        })}
    </div>
}

export function AggregationConfig({ condition = {}, onChange = () => { }, dataElement = {} }) {
    return <div className='aggregationconfig'>
        <div className='input-labeled'>
            <label>Type</label>
            <select name='type' value={condition?.type} onChange={(ev) => {
                ev.stopPropagation();
                onChange({ ...condition, 'type': ev.target.value, params: {} })
            }}>
                <option value={''}>Choose</option>
                <option value={'sex'}>Sex</option>
                <option value={'age'}>Age</option>
                <option value={'option'}>Option</option>
                <option value={'exact-answer'}>Exact answer</option>
                <option value={'sum-answer'}>Sum of answer</option>
                <option value={'exact-path'}>Exact path</option>
                <option value={'sum-exact-path'}>Sum of exact path</option>
            </select>
        </div>
        <div className='input-labeled'>
            <TypeForm
                dataElement={dataElement}
                type={condition?.type}
                form={condition?.params ?? {}}
                onChange={(updatedParams) => {
                    onChange({ ...condition, params: updatedParams })
                }}
            />
        </div>
        <div className='input-labeled'>
            <label>OrElse</label>
            <ExpansionTile title="OrElse condition" data={<AggregationConfig
                condition={condition.orElse}
                dataElement={dataElement}
                onChange={(updatedCondtion) => {
                    onChange({ ...condition, orElse: updatedCondtion })
                }} />} />
        </div>
    </div>
}

function TypeForm({ type = undefined, form = {}, onChange = () => { }, dataElement = {} }) {

    const handleChange = (ev) => {
        const { name, value } = ev.target;
        onChange({ ...form, [name]: value })
    }

    switch (type) {
        case "exact-path":
            return <ExactPathConditions dataElement={dataElement} form={form} onChange={(updatedForm) => {
                onChange({ ...form, ...updatedForm })
            }} />
        case "sum-exact-path":
            return <ExactPathSum dataElement={dataElement} form={form} onChange={(updatedForm) => {
                onChange({ ...form, ...updatedForm })
            }} />
        default:
            return <form onChange={handleChange} className='type-form'>{typeReturner(type)}</form>
    }

    function typeReturner(type) {
        switch (type) {
            case 'sex':
                return <SexConditions form={form} />
            case 'age':
                return <AgeConditions form={form} />
            case 'option':
                return <OptionsConditions form={form} dataElement={dataElement} />
            case 'exact-answer':
                return <ExactAnswerConditions form={form} dataElement={dataElement} />
            case 'sum-answer':
                return <SumAnswerConditions form={form} dataElement={dataElement} />
            default:
                return <p>Select a type</p>
        }
    }
}




