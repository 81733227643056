import React, { Component } from 'react'
import { connect } from 'react-redux';
import OcheInputs from '../components/oche_inputs';
import { copyWith } from '../logic/slice';
import { ViewRegisters } from './oche_registers'
import './styles/oche_form.css';

class OcheForm extends Component {

    state = {
        currentReg: null,
    }

    render() {
        const { currentReg } = this.state;
        const { registers, dispatch } = this.props;
        return (
            <div className='oche-form'>
                <ViewRegisters onTap={(reg) => { this.setState({ currentReg: { ...reg } }) }} />
                <OcheInputs key={currentReg?.code} reg={currentReg} onInputsChange={(inputsList) => {
                    dispatch(copyWith({
                        registers: [...registers].map((e) => {
                            return e.id !== currentReg?.id ? e : { ...currentReg, inputs: inputsList }
                        })
                    }))
                }} />
            </div>
        )
    }
}

const mapStateToPropsL = (_) => ({
    registers: _.default.registers,
});

export default connect(mapStateToPropsL)(OcheForm);


