/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { copyWith } from '../logic/slice';
import { getNewItemWithId } from '../utils/utils';


export default function OcheProvinces() {

    const { provinces } = useSelector(state => state.default);
    const dispatch = useDispatch();
    return <div className='input-labeled'>
        <div className='oche-title'>
            <label>Provinces and Municipalities ({provinces.length})</label>
            <button onClick={(ev) => {
                ev.stopPropagation();
                dispatch(copyWith({ provinces: [...provinces, getNewItemWithId({ currentList: provinces })] }));
            }}>Add</button>
        </div>
        {provinces.map((province, i) => {
            return <div key={province.id} className='options-inputs-row'>
                <p>{i + 1}.</p>
                <SingleProvince prov={province} onProvChange={(newProvince) => {
                    dispatch(copyWith({ provinces: [...provinces.map((e) => { return e.id !== province.id ? e : { ...province, ...newProvince } })] }));
                }} />
                <i className='modal-header fa fa-xmark' onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    dispatch(copyWith({ provinces: provinces.filter((e) => e.id !== province.id) }));
                }}></i>
            </div>
        })}
    </div>
}


function SingleProvince({ prov = {}, onProvChange = () => { } }) {

    const [formValue, setForm] = useState(prov);

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        setForm({ ...formValue, [name]: value })

    }

    useEffect(() => {
        onProvChange({ ...prov, ...formValue })
    }, [formValue])

    const defaultObj = {...prov,...formValue };
    const muni = defaultObj?.municipalities ?? [];
    console.log(defaultObj);

    return <form className='opts-single-form prov-modal' onChange={handleChange}>
        <div className='input-labeled' >
            <label>Name</label>
            <input value={defaultObj.name} placeholder="Province Name" name="name"/>
        </div>
        <div className='input-labeled' >
            <div className='oche-title'>
                <label>Municipalities ({muni.length ?? 0})</label>
                <button onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    setForm({
                        ...formValue,
                        municipalities:
                            muni.length
                                ? [...muni, getNewItemWithId({ currentList: muni })]
                                : [getNewItemWithId({ currentList: [] })]
                    })
                }}>Add</button>
            </div>
            {muni.map((mun, i) => {
                return <div key={mun.id} className='options-inputs-row'>
                    <p>{i + 1}.</p>
                    <Municipality munis={mun} onMunisChanges={(munis) => {
                        console.log("minicip", { ...mun, ...munis });
                        const newOne = { ...mun, ...munis };
                        setForm({
                            ...formValue,
                            municipalities: muni.map((e) => {
                                return e.id === newOne.id ? newOne : e;
                            })
                        })
                    }} />
                    <i className='modal-header fa fa-xmark' onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        console.log(formValue);
                        setForm({
                            ...formValue,
                            municipalities: muni.filter((el) => el.id !== mun?.id)
                        })
                    }}></i>
                </div>
            })}
        </div>
    </form>
}


function Municipality({ munis = {}, onMunisChanges = () => { } }) {
    return <div className='munis-div'>
        <input value={munis.name} placeholder="Municipality Name" onChange={(ev) => {
            ev.stopPropagation();
            onMunisChanges({ ...munis, name: ev.target.value });
        }} />
    </div>
}



