import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { exportToS3ForTesting } from '../utils/utils';
import Modal from './modal';
import OcheCountry from './oche_country';
import OcheForm from './oche_form';
import OcheLanguages from './oche_languages';
import OcheRegisters from './oche_registers';
import './styles/oche_home.css';
import dhis2 from './imgs/dhis2.png'
import OcheDHIS2 from './oche_dhis2';
import OcheDHIS2Datasets from './oche_dhis2_datasets';

class OcheHome extends Component {

    state = {
        country: false,
        languages: false,
        registers: false,
        forms: false,
        dhis2Space: false,
        dhis2DSpace: false,
        uploading: false,
    }

    openCloseModal(key, value) {
        this.setState({ ...this.state, [key]: value });
    }

    _saveFile({ upload = false }) {
        const { storeObj } = this.props;
        console.log(storeObj);
        const fileName = `OpenCarenet-${storeObj.name}-v${storeObj.version}`;
        const json = JSON.stringify(storeObj, null, 2);
        const blob = new Blob([json], { type: "application/json" });
        if (upload) {
            this.setState({ uploading: true });
            blob.name = fileName + ".json";
            blob.lastModified = new Date();
            exportToS3ForTesting(new File([blob], blob.name, { type: blob.type })).then((resp) => {
                this.setState({ uploading: false });
            });
        } else {
            const href = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = href;
            link.download = fileName + ".json";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }
    }

    render() {
        const { country, languages, registers, forms, uploading, dhis2Space, dhis2DSpace } = this.state;
        return (
            <div className='oche-home'>
                {country && <Modal child={<OcheCountry />} onClose={() => { this.openCloseModal('country', false) }} />}
                {languages && <Modal child={<OcheLanguages />} onClose={() => { this.openCloseModal('languages', false) }} />}
                {registers && <Modal child={<OcheRegisters />} onClose={() => { this.openCloseModal('registers', false) }} />}
                {dhis2Space && <Modal child={<OcheDHIS2 />} onClose={() => { this.openCloseModal('dhis2Space', false) }} />}
                {dhis2DSpace && <Modal child={<OcheDHIS2Datasets />} onClose={() => { this.openCloseModal('dhis2DSpace', false) }} />}
                {forms && <Modal child={<OcheForm />} onClose={() => { this.openCloseModal('forms', false) }} />}
                <div className='oche-home-save-actions'>
                    <Link to='/'><button>Home</button></Link>
                    <button onClick={(event) => {
                        event.stopPropagation();
                        this._saveFile({ upload: false });
                    }}>Save</button>
                    <button onClick={(event) => {
                        event.stopPropagation();
                        this._saveFile({ upload: true });
                    }}>Upload</button>
                    {uploading ? <i className='fa fa-spinner fa-spin' /> : <></>}
                </div>
                <div className='oche-home-actions'>
                    <div className='oche-home-single-home' onClick={() => { this.openCloseModal('country', true) }}>
                        <i className='c-red fa fa-earth-africa'></i>
                        <div className='divider' />
                        <p>Country</p>
                    </div>
                    <div className='oche-home-single-home' onClick={() => { this.openCloseModal('languages', true) }}>
                        <i className='c-red fa fa-language'></i>
                        <div className='divider' />
                        <p>Locales</p>
                    </div>
                </div>
                <div className='oche-home-actions'>
                    <div className='oche-home-single-home' onClick={() => { this.openCloseModal('registers', true) }}>
                        <i className='c-red fa fa-book'></i>
                        <div className='divider' />
                        <p>Registers</p>
                    </div>
                    <div className='oche-home-single-home' onClick={() => { this.openCloseModal('forms', true) }}>
                        <i className='c-red fa fa-clipboard-question'></i>
                        <div className='divider' />
                        <p>Forms</p>
                    </div>
                </div>
                <div className='oche-home-actions'>
                    <div className='oche-home-single-home' onClick={() => { this.openCloseModal('dhis2Space', true) }}>
                        <img className='c-red dash-img' src={dhis2} alt="dhis2" />
                        <div className='divider' />
                        <p>DHIS2</p>
                    </div>
                    <div className='oche-home-single-home' onClick={() => { this.openCloseModal('dhis2DSpace', true) }}>
                        <img className='c-red dash-img' src={dhis2} alt="dhis2" />
                        <div className='divider' />
                        <p>DHIS2 - DS</p>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (_) => ({
    name: _.default.name,
    version: _.default.version,
    languages: _.default.languages,
    registers: _.default.registers,
    provinces: _.default.provinces,
    dictionary: _.default.dictionary,
    copyright: _.default.copyright,
    storeObj: _.default,
});

export default connect(mapStateToProps)(OcheHome);



