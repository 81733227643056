import { useState } from "react";
import { useSelector } from "react-redux";


export function SexConditions({ form = {} }) {
    const { languages, genders } = useSelector(state => state.default);
    const [locale, setLocale] = useState(null)
    return <div className="aggregation-form">
        <div className='oche-languages-form'>
            <div className='input-labeled'>
                <label>Language</label>
                <select onChange={(ev) => {
                    ev.stopPropagation();
                    setLocale(ev.target.value)
                }}>
                    <option>Choose</option>
                    {languages.map((e) => {
                        return <option key={e.code} value={e.code}>{e.name}</option>
                    })}
                </select>
            </div>
            {locale ? <div className='input-labeled'>
                <label>Gender</label>
                <select name='gender' value={form.gender}>
                    <option>Choose</option>
                    {[...genders].map((e, i) => {
                        return <option value={e.id}>{e[locale] ?? ''}</option>
                    })}
                </select>
            </div> : <></>}
        </div>
    </div>
}

export function AgeConditions({ form = {} }) {
    return <div className="aggregation-form">
        <div className='oche-languages-form'>
            <div className='input-labeled'>
                <label>Age Type</label>
                <select name='ageType' value={form.ageType} >
                    <option>Choose</option>
                    <option value={'year'}>Year</option>
                    <option value={'month'}>Month</option>
                    <option value={'days'}>Days</option>
                </select>
            </div>
        </div>
        <div className='oche-languages-form'>
            <div className='input-labeled'>
                <label>Min age</label>
                <input name='minAge' type='number' placeholder='Min age' value={form.minAge} />
            </div>
            <div className='input-labeled'>
                <label>Max age</label>
                <input name='maxAge' type='number' placeholder='Max age' value={form.maxAge} />
            </div>
        </div>
    </div>
}

export function OptionsConditions({ form = {}, dataElement = {} }) {
    const { languages, registers } = useSelector(state => state.default);
    const [locale, setLocale] = useState(null)
    const cReg = registers.find((el) => el.code === dataElement.register)
    const [question, setQuestion] = useState(cReg?.inputs?.find((el) => el.key === form?.questionKey))
    return <div className="aggregation-form">
        <div className='oche-languages-form'>
            <div className='input-labeled'>
                <label>Language</label>
                <select onChange={(ev) => {
                    ev.stopPropagation();
                    setLocale(ev.target.value)
                }}>
                    <option>Choose</option>
                    {languages.map((e) => {
                        return <option key={e.code} value={e.code}>{e.name}</option>
                    })}
                </select>
            </div>
            {locale && cReg ? <div className='input-labeled'>
                <label>Question</label>
                <select name="questionKey" value={form.questionKey} placeholder="Question" onChange={(ev) => {
                    setQuestion(cReg?.inputs?.find((el) => el.key === ev.target.value))
                }}>
                    <option>Choose</option>
                    {cReg?.inputs?.filter((el) => el.type === 'select' || el.type === 'multi-select').map((e) => {
                        return <option key={e.key} value={e.key}>{e[`inputLabel_${locale}`]}</option>
                    })}
                </select>
            </div> : <></>}
        </div>
        {locale && cReg && question ? <div className='input-labeled'>
            <label>Option</label>
            <select name="optionValue" value={form.optionValue} placeholder="Option">
                <option>Choose</option>
                {question?.options?.map((e) => {
                    return <option key={e.value} value={e.value}>{e[`optionLabel_${locale}`]}</option>
                })}
            </select>
        </div> : <></>}
    </div>
}

export function ExactAnswerConditions({ form = {}, dataElement = {} }) {
    const { languages, registers } = useSelector(state => state.default);
    const [locale, setLocale] = useState(null)
    const cReg = registers.find((el) => el.code === dataElement.register)
    const [question, setQuestion] = useState(cReg?.inputs?.find((el) => el.key === form?.questionKey))
    return <div className="aggregation-form">
        <div className='oche-languages-form'>
            <div className='input-labeled'>
                <label>Language</label>
                <select onChange={(ev) => {
                    ev.stopPropagation();
                    setLocale(ev.target.value)
                }}>
                    <option>Choose</option>
                    {languages.map((e) => {
                        return <option key={e.code} value={e.code}>{e.name}</option>
                    })}
                </select>
            </div>
            {locale && cReg ? <div className='input-labeled'>
                <label>Question</label>
                <select name="questionKey" value={form.questionKey} placeholder="Question" onChange={(ev) => {
                    setQuestion(cReg?.inputs?.find((el) => el.key === ev.target.value))
                }}>
                    <option>Choose</option>
                    {cReg?.inputs?.filter((el) => el.type !== 'select').filter((il) => il.type !== 'multi-select').filter((ol) => ol.type !== 'multi-input').map((e) => {
                        return <option key={e.key} value={e.key}>{e[`inputLabel_${locale}`]}</option>
                    })}
                </select>
            </div> : <></>}
        </div>
        {locale && cReg && question ? <div className='oche-languages-form'>
            <div className='input-labeled'>
                <label>Data type</label>
                <input className='input-disable' type='text' placeholder='DataElement' value={question.type} />
            </div>
            {typeReturner(question.type)}
            {/* <div className='input-labeled'>
                <label>Parsable answer</label>
                <input name="answer" value={form.answer} />
            </div> */}
        </div> : <></>}
    </div>

    function typeReturner(type) {
        switch (type) {
            case 'text':
                return <div className='input-labeled'>
                    <label>Parsable answer</label>
                    <input name="answer" value={form.answer} />
                </div>
            case 'number':
                return <div className='input-labeled'>
                    <label>Parsable answer</label>
                    <input type="number" name="answer" value={form.answer} />
                </div>
            case 'bool':
                return <div className='input-labeled'>
                    <label>Boolean</label>
                    <select name="answer" value={form.answer} placeholder="Boolean">
                        <option>Choose</option>
                        <option value="true">true</option>
                        <option value="false">false</option>
                    </select>
                </div>
            case 'select':
                return <div className='input-labeled'>
                    <label>Option</label>
                    <select name="answer" value={form.answer} placeholder="Option">
                        <option>Choose</option>
                        {question?.options?.map((e) => {
                            return <option key={e.value} value={e.value}>{e[`optionLabel_${locale}`]}</option>
                        })}
                    </select>
                </div>
            case 'multi-select':
                return <div className='input-labeled'>
                    <label>Option</label>
                    <select name="answer" value={form.answer} placeholder="Option">
                        <option>Choose</option>
                        {question?.options?.map((e) => {
                            return <option key={e.value} value={e.value}>{e[`optionLabel_${locale}`]}</option>
                        })}
                    </select>
                </div>
            default:
                return <></>
        }
    }
}

export function SumAnswerConditions({ form = {}, dataElement = {} }) {
    const { languages, registers } = useSelector(state => state.default);
    const [locale, setLocale] = useState(null)
    const cReg = registers.find((el) => el.code === dataElement.register)
    return <div className="aggregation-form">
        <div className='oche-languages-form'>
            <div className='input-labeled'>
                <label>Language</label>
                <select onChange={(ev) => {
                    ev.stopPropagation();
                    setLocale(ev.target.value)
                }}>
                    <option>Choose</option>
                    {languages.map((e) => {
                        return <option key={e.code} value={e.code}>{e.name}</option>
                    })}
                </select>
            </div>
            {locale && cReg ? <div className='input-labeled'>
                <label>Question</label>
                <select name="questionKey" value={form.questionKey} placeholder="Question">
                    <option>Choose</option>
                    {cReg?.inputs?.filter((el) => el.type === 'number').map((e) => {
                        return <option key={e.key} value={e.key}>{e[`inputLabel_${locale}`]}</option>
                    })}
                </select>
            </div> : <></>}
        </div>
    </div>
}


export function ExactPathConditions({ form = {}, dataElement = {}, onChange = () => { } }) {
    const { languages, registers } = useSelector(state => state.default);
    const [locale, setLocale] = useState(null)
    const cReg = registers.find((el) => el.code === dataElement.register)
    const [question, setQuestion] = useState(cReg?.inputs?.find((el) => el.key === form?.questionKey))
    console.log(form)
    return <div className="aggregation-form">
        <div className='input-labeled'>
            <label>Language</label>
            <select onChange={(ev) => {
                ev.stopPropagation();
                setLocale(ev.target.value)
            }}>
                <option>Choose</option>
                {languages.map((e) => {
                    return <option key={e.code} value={e.code}>{e.name}</option>
                })}
            </select>
        </div>
        {locale && cReg ? <div className='input-labeled'>
            <label>Path {form?.path?.path ? `(${form?.path?.path})` : ''}</label>
            <select name="questionKey" value={form.questionKey} placeholder="Path" onChange={(ev) => {
                setQuestion(cReg?.inputs?.find((el) => el.key === ev.target.value))
                onChange({ ...form, questionKey: ev.target.value })
            }}>
                <option>Choose</option>
                {cReg?.inputs?.filter((el) => el.type === 'multi-input').map((e) => {
                    return <option key={e.key} value={e.key}>{e[`inputLabel_${locale}`]}</option>
                })}
            </select>
        </div> : <></>}
        {question && locale && cReg ? <LookUp input={question} locale={locale} path={form?.path?.obj[question.key]} onNewInput={(newPath) => {
            onChange({ ...form, path: newPath })
        }} /> : <></>}
    </div>
}


function LookUp({ input = {}, locale = '', onNewInput = () => { }, path = {} }) {
    console.log("Eoo", path)
    const dfolt = Object.keys(path ?? {}).length ? Object.keys(path)[0] : null;
    const [choosen, setChoosen] = useState(input?.inputs?.find((el) => el.key === dfolt))
    return <div className="lookup-div">
        <div className='input-labeled'>
            <select name="questionKey" value={dfolt} placeholder="Question" onChange={(ev) => {
                ev.stopPropagation();
                const inputAffected = input?.inputs?.find((el) => el.key === ev.target.value)
                if (inputAffected) {
                    setChoosen(inputAffected)
                    onNewInput({
                        path: `${input.key}->${inputAffected.key}`,
                        obj: {
                            [input.key]: {
                                [inputAffected.key]: null
                            }
                        }
                    })
                }

            }}>
                <option>Choose</option>
                {input?.inputs?.map((e) => {
                    return <option key={e.key} value={e.key}>{e[`inputLabel_${locale}`]}</option>
                })}
            </select>
        </div>
        {choosen?.type === 'multi-input' ? <LookUp input={choosen} locale={locale} path={path[choosen.key]} onNewInput={(newChoosen) => {
            onNewInput({
                path: `${input.key}->${newChoosen.path}`,
                obj: { [choosen.key]: newChoosen.obj }
            })
        }} /> : <ExactPathChecker question={choosen} locale={locale} condition={choosen ? path[choosen?.key] ?? {} : {}} onChange={(newObj) => {
            console.log(`${input.key}->${choosen.key}`, { [choosen.key]: newObj })
            onNewInput({
                path: `${input.key}->${choosen.key}`,
                obj: {
                    [input.key]: {
                        [choosen.key]: newObj
                    }
                }
            })
        }} />}
    </div>
}


function ExactPathChecker({ question = {}, locale, condition = {}, onChange = () => { } }) {
    console.log(condition)
    return <div className='aggregationconfig'>
        <div className='oche-languages-form'>
            <div className='input-labeled'>
                <label>Question</label>
                <input className='input-disable' type='text' placeholder='DataElement' value={question[`inputLabel_${locale}`]} />
            </div>
            <div className='input-labeled'>
                <label>Type</label>
                <input className='input-disable' type='text' placeholder='Type' value={question.type} />
            </div>
        </div>
        <div className='input-labeled'>
            <CheckerType
                question={question}
                type={question.type}
                locale={locale}
                form={condition ?? {}}
                onChange={(updatedParams) => {
                    onChange({ ...condition, ...updatedParams })
                }}
            />
        </div>
    </div>
}

function CheckerType({ question = {}, locale = '', type = undefined, form = {}, onChange = () => { } }) {

    const handleChange = (ev) => {
        const { name, value } = ev.target;
        onChange({ ...form, [name]: value })
    }

    return <form onChange={handleChange} className='type-form'>{typeReturner(type)}</form>
    function typeReturner(type) {
        switch (type) {
            case 'text':
                return <div className='input-labeled'>
                    <label>Parsable answer</label>
                    <input name="target" value={form.target} />
                </div>
            case 'number':
                return <div className='input-labeled'>
                    <label>Parsable answer</label>
                    <input type="number" name="target" value={form.target} />
                </div>
            case 'bool':
                return <div className='input-labeled'>
                    <label>Boolean</label>
                    <select name="target" value={form.target} placeholder="Boolean">
                        <option>Choose</option>
                        <option value="true">true</option>
                        <option value="false">false</option>
                    </select>
                </div>
            case 'select':
                return <div className='input-labeled'>
                    <label>Option</label>
                    <select name="target" value={form.target} placeholder="Option">
                        <option>Choose</option>
                        {question?.options?.map((e) => {
                            return <option key={e.value} value={e.value}>{e[`optionLabel_${locale}`]}</option>
                        })}
                    </select>
                </div>
            case 'multi-select':
                return <div className='input-labeled'>
                    <label>Option</label>
                    <select name="target" value={form.target} placeholder="Option">
                        <option>Choose</option>
                        {question?.options?.map((e) => {
                            return <option key={e.value} value={e.value}>{e[`optionLabel_${locale}`]}</option>
                        })}
                    </select>
                </div>
            default:
                return <></>
        }
    }
}

export function ExactPathSum({ form = {}, dataElement = {}, onChange = () => { } }) {
    const { languages, registers } = useSelector(state => state.default);
    const [locale, setLocale] = useState(null)
    const cReg = registers.find((el) => el.code === dataElement.register)
    const [question, setQuestion] = useState(cReg?.inputs?.find((el) => el.key === form?.questionKey))
    return <div className="aggregation-form">
        <div className='input-labeled'>
            <label>Language</label>
            <select onChange={(ev) => {
                ev.stopPropagation();
                setLocale(ev.target.value)
            }}>
                <option>Choose</option>
                {languages.map((e) => {
                    return <option key={e.code} value={e.code}>{e.name}</option>
                })}
            </select>
        </div>
        {locale && cReg ? <div className='input-labeled'>
            <label>Path {form?.path?.path ? `(${form?.path?.path})` : ''}</label>
            <select name="questionKey" value={form.questionKey} placeholder="Path" onChange={(ev) => {
                setQuestion(cReg?.inputs?.find((el) => el.key === ev.target.value))
                onChange({ ...form, questionKey: ev.target.value })
            }}>
                <option>Choose</option>
                {cReg?.inputs?.filter((el) => el.type === 'multi-input').map((e) => {
                    return <option key={e.key} value={e.key}>{e[`inputLabel_${locale}`]}</option>
                })}
            </select>
        </div> : <></>}
        {question && locale && cReg ? <ExactPathSumLookUp input={question} locale={locale} path={form?.path?.obj[question.key]} onNewInput={(newPath) => {
            onChange({ ...form, path: newPath })
        }} /> : <></>}
    </div>
}


function ExactPathSumLookUp({ input = {}, locale = '', onNewInput = () => { }, path = {} }) {
    const dfolt = Object.keys(path ?? {}).length ? Object.keys(path)[0] : null;
    const [choosen, setChoosen] = useState(input?.inputs?.find((el) => el.key === dfolt))
    return <div className="lookup-div">
        <div className='input-labeled'>
            <select name="questionKey" value={dfolt} placeholder="Question" onChange={(ev) => {
                ev.stopPropagation();
                const inputAffected = input?.inputs?.find((el) => el.key === ev.target.value)
                if (inputAffected) {
                    setChoosen(inputAffected)
                    onNewInput({
                        path: `${input.key}->${inputAffected.key}`,
                        obj: {
                            [input.key]: {
                                [inputAffected.key]: null
                            }
                        }
                    })
                }

            }}>
                <option>Choose</option>
                {input?.inputs?.map((e) => {
                    return <option key={e.key} value={e.key}>{e[`inputLabel_${locale}`]}</option>
                })}
            </select>
        </div>
        {choosen?.type === 'multi-input' ? <ExactPathSumLookUp input={choosen} locale={locale} path={path[choosen.key]} onNewInput={(newChoosen) => {
            onNewInput({
                path: `${input.key}->${newChoosen.path}`,
                obj: { [choosen.key]: newChoosen.obj }
            })
        }} /> : <></>}
    </div>
}