

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { copyWith } from '../logic/slice';
import { apiCall } from '../providers/api';
import './styles/oche_dhis2.css';

export default function OcheDHIS2() {


    return (
        <div className='oche-dhis2'>
            <DisplayDataSets />
            <DisplayDataSets displayOnly={true} />
        </div>
    )
}

async function fecthDataSets({ url = "", usrn = "", pwd = "" }) {
    const path = "dataSets?paging=false&fields=id,name,shortName,version,periodType,created";
    return await apiCall({
        baseUrl: url,
        endPoint: path,
        method: 'GET',
        mode: 'no-cors',
        headers: {
            Authorization: `Basic ${window.btoa(`${usrn}:${pwd}`)}`,
        },
    }).then((res) => {
        if (res.error) {
            return [];
        } else {
            return res.dataSets;
        }
    })

}

function DisplayDataSets({ onTap = () => { }, displayOnly = false }) {
    const { dhis2, dataSets } = useSelector(state => state.default);
    const { baseUrl, username, password } = dhis2 ?? {};
    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(false)
    const [query, setQuery] = useState('')
    const dispatch = useDispatch();

    useEffect(() => {
        if (!displayOnly) {
            setLoading(true)
            fecthDataSets({
                url: baseUrl,
                usrn: username,
                pwd: password
            }).then((_) => {
                setLoading(false)
                setItems(_)
            })
        }

    }, [baseUrl, username, password, displayOnly]);
    const items2C = displayOnly ? dataSets : items;
    let itemsToDisplay = items2C.filter((el) => String(el.name).toLowerCase().includes(String(query).toLowerCase()))
    itemsToDisplay.sort((a, b) => a.name.localeCompare(b.name))
    return <div className='oche_dataSets'>
        <div className="o-d-header">
            <p>{displayOnly ? "Supported" : "DHIS2"} Datasets ({itemsToDisplay.length})</p>
            {!displayOnly ? <i className={`fa fa-refresh ${loading ? 'fa-spin' : ''}`} onClick={(ev) => {
                if (!displayOnly) {
                    setLoading(true)
                    fecthDataSets({
                        url: baseUrl,
                        usrn: username,
                        pwd: password
                    }).then((_) => {
                        setLoading(false)
                        setItems(_)
                        if (_.length) {
                            var cDataSets = [...dataSets];
                            for (let i = 0; i < dataSets.length; i++) {
                                let cSet = dataSets[i];
                                console.log(i, cSet)
                                let existIf = items.find((el) => el.id === cSet.id);
                                if (!existIf) {
                                    cDataSets = [...cDataSets].filter((el) => el.id !== cSet.id);
                                }
                            }
                            dispatch(copyWith({
                                dataSets: cDataSets
                            }))
                        }
                    })
                }
            }} /> : <></>}
        </div>
        <div className='divider' />
        <input
            placeholder='Filter by name'
            onChange={(ev) => {
                const { value } = ev.target;
                setQuery(value)
            }}
        />
        <div className='s-dataset-list'>
            {itemsToDisplay.map((dSet, i) => {
                const { id, name, version, periodType, created } = dSet;
                const isActive = dataSets.filter((el) => el.id === dSet.id).length;
                return <div key={id} className='s-dataset'>
                    <span className={isActive ? 's-d-l-active' : ''} onClick={(ev) => {
                        ev.stopPropagation();
                        if (isActive) {
                            dispatch(copyWith({
                                dataSets: dataSets.filter((el) => el.id !== dSet.id)
                            }))
                        } else {
                            dispatch(copyWith({
                                dataSets: [...dataSets, dSet]
                            }))
                        }

                    }} />
                    <div className='s-d-body'>
                        <p>{String(name).trim()}</p>
                        <div className='divider' />
                        <p>v{version} - {periodType}</p>
                        <p>Created : {new Date(created).toLocaleDateString()}</p>
                    </div>
                </div>
            })}
        </div>
    </div>
}



