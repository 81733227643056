import React from 'react'
import { appName } from '../config/config';
import './styles/oche_main.css';
import oche from "./imgs/oche.png";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { continueSession } from '../logic/slice';

export default function OcheMain() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="oche-main">
      <div className='oche-main-left'>
        <h1 className='large-title'>{appName}</h1>
        <div className='divider' />
        <div className='oche-main-actions'>
          <Link to='/session'><button>START NEW SESSION</button></Link>
          <input id='continue-session' type='file' accept='application/json' style={{ display: 'none' }} onChange={(ev) => {
            const file = ev.target.files[0];
            if (file.type === 'application/json') {
              const reader = new window.FileReader();
              reader.onload = function (e) {
                console.log(JSON.parse(reader.result));
                dispatch(continueSession(JSON.parse(reader.result)))
                navigate('/session');
              }
              reader.readAsText(file);
            }
          }} />
          <label className='button-label' htmlFor='continue-session'>CONTINUE SESSION</label>
        </div>
      </div>
      <div className='oche-main-right'>
        <img src={oche} alt="donexa logo" />
      </div>
    </div>
  );
}
