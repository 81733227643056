import React, { useState } from 'react'


export function ExpansionTile({ title = '', data = <div /> }) {
    const [collapsed, setCollapsed] = useState(false);
    return <div className='s-action-main'>
        <div className='s-action-part'>
            <p>{title}</p>
            <i className={`fa fa-arrow-${collapsed ? 'up' : 'down'}`} onClick={(ev) => {
                ev.stopPropagation();
                setCollapsed(!collapsed);
            }} />
        </div>
        {collapsed && <div className='s-action-data'>{data}</div>}
    </div>
}



