export const apiCall = async ({ body = null, method = "POST", baseUrl = '', endPoint = "", headers = {}, needHeaders = false, mode = undefined }) => await fetch(
    `${baseUrl}/${endPoint}`, {
    method: method,
    body: body,
    headers: { "Content-Type": "application/json", ...headers },
}).then(async (res) => {
    const respB = await res.json();
    if (res.ok) {
        return needHeaders ? {
            headers: res.headers,
            ...respB,
        } : respB;
    } else {
        return { "error": respB }
    }

}).catch((error) => { return { "error": error } });