import React, { Component, useState } from 'react'
import { useSelector } from 'react-redux';
import { getNewItemWithId } from '../utils/utils';
import { ExpansionTile } from '../pages/oche_canevas';
import '../pages/styles/oche_home.css';

export default class OcheInputs extends Component {

    state = {
        inputs: this.props?.reg?.inputs ?? [],
    }

    componentDidUpdate(prevPros, prevState) {
        if (prevState !== this.state) {
            const { onInputsChange } = this.props;
            const { inputs } = this.state;
            if (onInputsChange) {
                onInputsChange(inputs);
            }
        }
    }

    render() {
        const { inputs } = this.state;
        const { reg } = this.props;
        return reg ? (
            <div className='oche-inputs'>
                <div className='oche-inputs-title'>
                    <p className='p-medium'>Form's inputs ({inputs.length})</p>
                    <button onClick={(ev) => {
                        ev.stopPropagation();
                        this.setState({ inputs: [...inputs, getNewItemWithId({ currentList: inputs })] })
                    }}>Add</button>
                </div>
                <div className='divider' />
                {inputs.map((e, i) => {
                    return <ExpansionTile key={e.id} title={e?.key ?? ""} data={<div className='oche-inputs-item'>
                        <p>{i + 1}.</p>
                        <SingleInput item={e} onChangeF={(input) => {
                            console.log("Coming input", input)
                            this.setState({
                                inputs: inputs.map((eachInput) => {
                                    return eachInput.id !== input.id ? eachInput : { ...eachInput, ...input }
                                })
                            })
                        }} />
                        <i className='modal-header fa fa-xmark' onClick={(ev) => {
                            ev.stopPropagation();
                            this.setState({ inputs: inputs.filter((element) => element.id !== e.id) });
                        }}></i>
                    </div>} />
                })}
            </div>
        ) : <></>
    }
}


function SingleInput({ item = {}, onChangeF = () => { } }) {

    const { languages, genders } = useSelector(state => state.default);
    const [locale, setLocale] = useState(null)

    return <div className='single-oche-input'>
        <div className='input-labeled'>
            <label>Input Label</label>
            {[...languages].map((lng, i) => {
                const iName = `inputLabel_${lng.code}`;
                return <input
                    key={i}
                    name={iName}
                    placeholder={`Input label (${lng.name})`}
                    type={'name'}
                    value={item[`inputLabel_${lng.code}`]}
                    onChange={(ev) => {
                        ev.stopPropagation();
                        onChangeF({ ...item, [iName]: ev.target.value });
                    }}
                />
            })}
        </div>
        <div className='oche-languages-form'>
            <div className='input-labeled'>
                <label>Key</label>
                <input name='key' type='text' placeholder='Input Key' value={item[`key`]} onChange={(ev) => {
                    ev.stopPropagation();
                    onChangeF({ ...item, 'key': ev.target.value });
                }} />
            </div>
            <div className='input-labeled'>
                <label>Type</label>
                <select name='type' value={item[`type`]} onChange={(ev) => {
                    ev.stopPropagation();
                    onChangeF({ ...item, 'type': ev.target.value });
                }}>
                    <option value={null}>Type</option>
                    <option value="text">Text</option>
                    <option value="number">Number</option>
                    <option value="bool">Boolean</option>
                    <option value="select">Select</option>
                    <option value="multi-select">Multi Select</option>
                    <option value="multi-input">Multi Input</option>
                </select>
            </div>
        </div>
        <div className='input-labeled'>
            <label>Is required</label>
            <select name='isRequired' value={item[`isRequired`]} onChange={(ev) => {
                ev.stopPropagation();
                onChangeF({ ...item, 'isRequired': ev.target.value });
            }}>
                <option value={null}>Choose</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
            </select>
        </div>
        <div className='oche-languages-form'>
            <div className='input-labeled'>
                <label>Min age</label>
                <input name='minAge' type='number' placeholder='Min age' value={item.minAge} onChange={(ev) => {
                    ev.stopPropagation();
                    onChangeF({ ...item, 'minAge': ev.target.value });
                }} />
            </div>
            <div className='input-labeled'>
                <label>Max age</label>
                <input name='maxAge' type='number' placeholder='Max age' value={item.maxAge} onChange={(ev) => {
                    ev.stopPropagation();
                    onChangeF({ ...item, 'maxAge': ev.target.value });
                }} />
            </div>
        </div>
        <div className='oche-languages-form'>
            <div className='input-labeled'>
                <label>Age Type</label>
                <select name='ageType' value={item.ageType} onChange={(ev) => {
                    ev.stopPropagation();
                    onChangeF({ ...item, 'ageType': ev.target.value });
                }}>
                    <option>Choose</option>
                    <option value={'year'}>Year</option>
                    <option value={'month'}>Months</option>
                    <option value={'days'}>Days</option>
                </select>
            </div>
        </div>
        <div className='oche-languages-form'>
            <div className='input-labeled'>
                <label>Language</label>
                <select onChange={(ev) => {
                    ev.stopPropagation();
                    setLocale(ev.target.value)
                }}>
                    <option>Choose</option>
                    {languages.map((e) => {
                        return <option key={e.code} value={e.code}>{e.name}</option>
                    })}
                </select>
            </div>
            {locale ? <div className='input-labeled'>
                <label>Gender</label>
                <select name='gender' value={item.gender} onChange={(ev) => {
                    ev.stopPropagation();
                    onChangeF({ ...item, 'gender': ev.target.value });
                }}>
                    <option>Choose</option>
                    {[...genders].map((e, i) => {
                        return <option value={e.id}>{e[locale] ?? ''}</option>
                    })}
                </select>
            </div> : <></>}
        </div>

        <ExpansionTile title='Options' data={<OptionsInput dOptions={item?.options ?? []} onChangeOf={(ots) => {
            onChangeF({ ...item, options: ots });
        }} />} />
        <ExpansionTile title='Additional Inputs' data={<Inputs4Input dInputs={item?.inputs ?? []} onChangeOf={(ots) => {
            onChangeF({ ...item, inputs: ots });
        }} />} />

        {/* <ExclusionInput
            onChangeOf={(inputsTs) => {
                const exclusionFromParent = item.exclusion ?? {};
                onChangeF({ ...item, exclusion: { ...exclusionFromParent, inputs: inputsTs } });
            }}
            onChangeOfEx={(exclusion) => {
                console.log(exclusion);
                const exclusionFromParent = item.exclusion ?? {};
                onChangeF({ ...item, exclusion: { ...exclusionFromParent, exclusion: exclusion } });
            }}
            exclusion={item?.exclusion?.exclusion ?? {}}
            dInputs={item?.exclusion?.inputs ?? []}
        /> */}
    </div>
}


// function ExclusionInput({ onChangeOf = () => { }, dInputs = [], onChangeOfEx = () => { }, exclusion }) {
//     return <div className='oche-inputsr for-inputs'>
//         <div className='oche-inputs-title'>
//             <p className='p-medium'>Exclusion ({dInputs.length})</p>
//             <button onClick={(ev) => {
//                 ev.preventDefault();
//                 ev.stopPropagation();
//                 if (!(dInputs.length)) {
//                     onChangeOf([...dInputs, getNewItemWithId({ currentList: dInputs })]);
//                 }
//             }}>Add</button>
//         </div>
//         <div className='divider' />
//         <div className='oche-languages-form'>
//             <div className='input-labeled'>
//                 <label>Expectation</label>
//                 <input name='value' placeholder='Value' value={exclusion.value} onChange={(ev) => {
//                     ev.stopPropagation();
//                     onChangeOfEx({ ...exclusion, 'value': ev.target.value });
//                 }} />
//             </div>
//             <div className='input-labeled'>
//                 <label>Type</label>
//                 <select name='type' value={exclusion[`type`]} onChange={(ev) => {
//                     ev.stopPropagation();
//                     onChangeOfEx({ ...exclusion, 'type': ev.target.value });
//                 }}>
//                     <option value={null}>Type</option>
//                     <option value="text">Text</option>
//                     <option value="number">Number</option>
//                     <option value="bool">Boolean</option>
//                     <option value="select">Select</option>
//                     <option value="multi-select">Multi Select</option>
//                     <option value="multi-input">Multi Input</option>
//                 </select>
//             </div>
//         </div>
//         <div className='divider' />
//         {dInputs.map((e, i) => {
//             return <ExpansionTile key={e.id} title={e?.key ?? ""} data={<div className='oche-inputs-item'>
//                 <p>{i + 1}.</p>
//                 <SingleInput item={e} onChangeF={(input) => {
//                     onChangeOf([...dInputs.map((element) => {
//                         return element.id !== e.id ? element : { ...element, ...input }
//                     })])
//                 }} />
//                 <i className='modal-header fa fa-xmark' onClick={(ev) => {
//                     ev.stopPropagation();
//                     onChangeOf([...dInputs.filter((element) => element.id !== e.id)]);
//                 }}></i>
//             </div>} />
//         })}
//     </div>
// }

function OptionsInput({ onChangeOf = () => { }, dOptions = [] }) {
    return <div className='options-reg'>
        <div className='oche-title'>
            <label>Options</label>
            <button onClick={(ev) => {
                ev.preventDefault();
                onChangeOf([...dOptions, getNewItemWithId({ currentList: dOptions })]);
            }}>Add</button>
        </div>
        <div className='divider' />
        {dOptions.map((optionFor, i) => {
            return <ExpansionTile key={i} title={optionFor?.value ?? ''} data={<div className='options-inputs-row'>
                <p>{i + 1}.</p>
                <SingleOption item={optionFor} onChangeWith={(newOptionObject) => {
                    onChangeOf([...dOptions.map((element) => {
                        return element.id !== optionFor.id ? element : { ...element, ...newOptionObject }
                    })])
                }} />
                <i className='modal-header fa fa-xmark' onClick={(ev) => {
                    ev.stopPropagation();
                    onChangeOf([...dOptions.filter((element) => element.id !== optionFor.id)]);
                }}></i>
            </div>} />
        })}
    </div>
}

function SingleOption({ item = {}, onChangeWith = () => { } }) {

    const { languages, genders } = useSelector(state => state.default);

    const handleChange = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const { name, value } = event.target;
        onChangeWith({ ...item, [name]: value })
    }

    return <form className='opts-single-form' onChange={handleChange}>
        <div className='input-labeled'>
            <label>Option Label</label>
            {[...languages].map((lng, i) => {
                return <input
                    key={i}
                    name={`optionLabel_${lng.code}`}
                    placeholder={`Option label (${lng.name})`}
                    type={'name'}
                    value={item[`optionLabel_${lng.code}`]}
                />
            })}
        </div>
        <div className='input-labeled'>
            <label>Value</label>
            <input type='name' name='value' placeholder='Value' value={item.value} />
        </div>
        <div className='oche-languages-form'>
            <div className='input-labeled'>
                <label>Min age</label>
                <input name='minAge' type='number' placeholder='Min age' value={item.minAge} />
            </div>
            <div className='input-labeled'>
                <label>Max age</label>
                <input name='maxAge' type='number' placeholder='Max age' value={item.maxAge} />
            </div>
        </div>
        <div className='oche-languages-form'>
            <div className='input-labeled'>
                <label>Age Type</label>
                <select name='ageType' value={item.ageType}>
                    <option>Choose</option>
                    <option value={'year'}>Year</option>
                    <option value={'month'}>Months</option>
                    <option value={'days'}>Days</option>
                </select>
            </div>
            <div className='input-labeled'>
                <label>Gender</label>
                <select name='gender' value={item.gender}>
                    <option>Choose</option>
                    {[...genders].map((e, i) => {
                        return <option value={e.id}>{e?.fr ?? ''}</option>
                    })}
                </select>
            </div>
        </div>

    </form>
}


function Inputs4Input({ onChangeOf = () => { }, dInputs = [] }) {
    return <div className='oche-inputsr for-inputs'>
        <div className='oche-inputs-title'>
            <p className='p-medium'>Additional Inputs ({dInputs.length})</p>
            <button onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                onChangeOf([...dInputs, getNewItemWithId({ currentList: dInputs })]);
            }}>Add</button>
        </div>
        <div className='divider' />
        {dInputs.map((e, i) => {
            return <ExpansionTile key={e.id} title={e?.key ?? ""} data={<div className='oche-inputs-item'>
                <p>{i + 1}.</p>
                <SingleInput item={e} onChangeF={(input) => {
                    onChangeOf([...dInputs.map((element) => {
                        return element.id !== e.id ? element : { ...element, ...input }
                    })])
                }} />
                <i className='modal-header fa fa-xmark' onClick={(ev) => {
                    ev.stopPropagation();
                    onChangeOf([...dInputs.filter((element) => element.id !== e.id)]);
                }}></i>
            </div>} />
        })}
    </div>



}

