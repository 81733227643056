export const getNewItemWithId = ({ currentList = [] }) => {
    if (currentList.length < 1) return { id: 1 };
    return { id: currentList[currentList.length - 1].id + 1 }
}


export const exportToS3ForTesting = async (file) => {
    console.log(file.name, "Name");
    const form = new FormData();
    console.log(file);
    form.append('key', `pv-d/${file.name}`);
    form.append('acl', 'public-read');
    form.append('file', file);
    return await fetch('https://s3.us-east-1.amazonaws.com/dpictures', {
        body: form,
        method: 'POST',
    }).then(async (res) => {
        const respB = await res.json();
        if (res.ok) {
            return {
                headers: res.headers,
                ...respB,
            }
        } else {
            console.log(respB)
            return { "error": respB }
        }
    }).catch((error) => {
        console.log(error)
        return { "error": error }
    });
}
