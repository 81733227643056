import { configureStore } from '@reduxjs/toolkit'
import defaultSlice from './slice'

export default configureStore({
    reducer: {
        default: defaultSlice,
    },
});


